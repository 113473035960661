import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import UserProfileCard from '@components/user/UserProfileCard';
import { useClinicianService } from '@actions';

function ClinicianScreen() {
	const dispatch = useDispatch();
	const clinicianService = useClinicianService();
	const clinicianProfiles = useSelector((state) => state.clinicians.clinicianCrps);

	const { id } = useParams();

	const handleGetClinicianById = async () => {
		const userId = parseInt(id, 10);
		await clinicianService.getClinicianByUserId(userId);
	};

	useEffect(() => {
		handleGetClinicianById();
	}, [dispatch, id]);

	return (
		<div>
			<Row className="text-center py-1">
				<Col>
					<h1>Clinician Information</h1>
				</Col>
			</Row>
			<Row className="text-center py-1">
				<Col xs={12} className="text-center d-flex justify-content-center align-items-center">
					<UserProfileCard clinicianProfiles={clinicianProfiles} />
				</Col>
			</Row>
		</div>
	);
}


export default ClinicianScreen;
